/* models/sources/remote_data_source */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var column_data_source_1 = require(200) /* ./column_data_source */;
    var p = require(15) /* core/properties */;
    var RemoteDataSource = /** @class */ (function (_super) {
        tslib_1.__extends(RemoteDataSource, _super);
        function RemoteDataSource(attrs) {
            return _super.call(this, attrs) || this;
        }
        RemoteDataSource.prototype.get_column = function (colname) {
            var column = this.data[colname];
            return column != null ? column : [];
        };
        RemoteDataSource.prototype.initialize = function () {
            _super.prototype.initialize.call(this);
            this.setup();
        };
        RemoteDataSource.initClass = function () {
            this.prototype.type = 'RemoteDataSource';
            this.define({
                data_url: [p.String],
                polling_interval: [p.Number],
            });
        };
        return RemoteDataSource;
    }(column_data_source_1.ColumnDataSource));
    exports.RemoteDataSource = RemoteDataSource;
    RemoteDataSource.initClass();
}
