/* models/widgets/pretext */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var markup_1 = require(416) /* ./markup */;
    var dom_1 = require(5) /* core/dom */;
    var PreTextView = /** @class */ (function (_super) {
        tslib_1.__extends(PreTextView, _super);
        function PreTextView() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        PreTextView.prototype.render = function () {
            _super.prototype.render.call(this);
            var content = dom_1.pre({ style: { overflow: "auto" } }, this.model.text);
            this.markupEl.appendChild(content);
        };
        return PreTextView;
    }(markup_1.MarkupView));
    exports.PreTextView = PreTextView;
    var PreText = /** @class */ (function (_super) {
        tslib_1.__extends(PreText, _super);
        function PreText(attrs) {
            return _super.call(this, attrs) || this;
        }
        PreText.initClass = function () {
            this.prototype.type = "PreText";
            this.prototype.default_view = PreTextView;
        };
        return PreText;
    }(markup_1.Markup));
    exports.PreText = PreText;
    PreText.initClass();
}
