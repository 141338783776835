/* models/axes/index */ function _(require, module, exports) {
    var axis_1 = require(79) /* ./axis */;
    exports.Axis = axis_1.Axis;
    var categorical_axis_1 = require(80) /* ./categorical_axis */;
    exports.CategoricalAxis = categorical_axis_1.CategoricalAxis;
    var continuous_axis_1 = require(81) /* ./continuous_axis */;
    exports.ContinuousAxis = continuous_axis_1.ContinuousAxis;
    var datetime_axis_1 = require(82) /* ./datetime_axis */;
    exports.DatetimeAxis = datetime_axis_1.DatetimeAxis;
    var linear_axis_1 = require(84) /* ./linear_axis */;
    exports.LinearAxis = linear_axis_1.LinearAxis;
    var log_axis_1 = require(85) /* ./log_axis */;
    exports.LogAxis = log_axis_1.LogAxis;
    var mercator_axis_1 = require(86) /* ./mercator_axis */;
    exports.MercatorAxis = mercator_axis_1.MercatorAxis;
}
