/* models/formatters/categorical_tick_formatter */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var tick_formatter_1 = require(113) /* ./tick_formatter */;
    var array_1 = require(21) /* core/util/array */;
    var CategoricalTickFormatter = /** @class */ (function (_super) {
        tslib_1.__extends(CategoricalTickFormatter, _super);
        function CategoricalTickFormatter(attrs) {
            return _super.call(this, attrs) || this;
        }
        CategoricalTickFormatter.initClass = function () {
            this.prototype.type = 'CategoricalTickFormatter';
        };
        CategoricalTickFormatter.prototype.doFormat = function (ticks, _axis) {
            return array_1.copy(ticks);
        };
        return CategoricalTickFormatter;
    }(tick_formatter_1.TickFormatter));
    exports.CategoricalTickFormatter = CategoricalTickFormatter;
    CategoricalTickFormatter.initClass();
}
