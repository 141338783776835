/* models/formatters/tick_formatter */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var model_1 = require(59) /* ../../model */;
    var TickFormatter = /** @class */ (function (_super) {
        tslib_1.__extends(TickFormatter, _super);
        function TickFormatter(attrs) {
            return _super.call(this, attrs) || this;
        }
        TickFormatter.initClass = function () {
            this.prototype.type = "TickFormatter";
        };
        return TickFormatter;
    }(model_1.Model));
    exports.TickFormatter = TickFormatter;
    TickFormatter.initClass();
}
