/* models/ranges/data_range */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var range_1 = require(183) /* ./range */;
    var p = require(15) /* core/properties */;
    var DataRange = /** @class */ (function (_super) {
        tslib_1.__extends(DataRange, _super);
        function DataRange(attrs) {
            return _super.call(this, attrs) || this;
        }
        DataRange.initClass = function () {
            this.prototype.type = "DataRange";
            this.define({
                names: [p.Array, []],
                renderers: [p.Array, []],
            });
        };
        return DataRange;
    }(range_1.Range));
    exports.DataRange = DataRange;
    DataRange.initClass();
}
