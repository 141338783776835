// End point to performance inference on a sample
// export const address = '68.173.160.106'
// export const address = '192.168.1.24'
// export const port ='5000' // production

/////// for using non secure connection

// export const address = '68.173.160.106'
// export const port ='5001' // development
// export const API_URL_INFER = 'http://'+address + ':'+port+'/api/infer'
// // End point to retrieve sample list
// export const API_URL_SAMPLES = 'http://'+address + ':'+port+'/api/samples'
// // URL directory of the sample
// export const API_URL_SAMPLE_DOWNLOAD = 'http://'+address + ':'+port+'/samples'
// export const API_URL_STRATEGY1 = 'http://'+address + ':'+port+'/api/strategy1'
// export const API_URL_STRATEGY2 = 'http://'+address + ':'+port+'/api/strategy2'
// export const API_URL_CRYPTO1 = 'http://'+address + ':'+port+'/api/crypto1'
// export const API_URL_DASHBOARD1= 'http://'+address + ':'+port+'/api/dashboard1'
// export const API_URL_CRYPTOONE = 'http://'+address + ':'+port+'/api/cryptoone'
// export const API_URL_VOLTRADING= 'http://'+address + ':'+port+'/api/voltrading'
// export const API_URL_HCA= 'http://'+address + ':'+port+'/api/hca_1'
// export const API_URL_MODELCOMP= 'http://'+address + ':'+port+'/api/modelscomp'
// export const API_URL_TICKERMODELS= 'http://'+address + ':'+port+'/api/tickermodels'
// export const API_URL_PORTFOLIO= 'http://'+address + ':'+port+'/api/portfolio_analytic_1'
// export const API_URL_PORTFOLIO2= 'http://'+address + ':'+port+'/api/portfolio_analytic_2'
// export const API_URL_PORTFOLIO_ANALYSIS= 'http://'+address + ':'+port+'/api/portfolio_analytic_1_analysis'
// export const API_URL_PORTFOLIO_STYLE_ATTR= 'http://'+address + ':'+port+'/api/portfolio_style_attr'
// export const API_URL_DLFM= 'http://'+address + ':'+port+'/api/dlfm'
// export const API_URL_BACKTESTIN01= 'http://'+address + ':'+port+'/api/backtesting01'
// export const API_URL_MARKET= 'http://'+address + ':'+port+'/api/market'

/////// for using secure connection using ngrok
// https://ea06-68-173-160-106.ngrok-free.app

// ea06-68-173-160-106.ngrok-free.app 이거는 무료버전이라서  바뀔수 있음
export const address = 'kodiak-fond-needlessly.ngrok-free.app' // 이거는 permanent 버전임
export const API_URL_INFER = 'https://'+address + '/api/infer'
// End point to retrieve sample list
export const API_URL_SAMPLES = 'https://'+address + '/api/samples'
// URL directory of the sample
export const API_URL_SAMPLE_DOWNLOAD = 'https://'+address +'/samples'
export const API_URL_STRATEGY1 = 'https://'+address +'/api/strategy1'
export const API_URL_STRATEGY2 = 'https://'+address +'/api/strategy2'
export const API_URL_CRYPTO1 = 'https://'+address +'/api/crypto1'
export const API_URL_DASHBOARD1= 'https://'+address +'/api/dashboard1'
export const API_URL_CRYPTOONE = 'https://'+address +'/api/cryptoone'
export const API_URL_VOLTRADING= 'https://'+address +'/api/voltrading'
export const API_URL_HCA= 'https://'+address +'/api/hca_1'
export const API_URL_MODELCOMP= 'https://'+address +'/api/modelscomp'
export const API_URL_TICKERMODELS= 'https://'+address +'/api/tickermodels'
export const API_URL_PORTFOLIO= 'https://'+address +'/api/portfolio_analytic_1'
export const API_URL_PORTFOLIO2= 'http://'+address + '/api/portfolio_analytic_2'
export const API_URL_PORTFOLIO_STYLE_ATTR= 'https://'+address +'/api/portfolio_style_attr'
export const API_URL_DLFM= 'https://'+address +'/api/dlfm'
export const API_URL_BACKTESTIN01= 'https://'+address +'/api/backtesting01'
export const API_URL_MARKET= 'https://'+address +'/api/market'
export const API_URL_PORTFOLIO_ANALYSIS= 'https://'+address + '/api/portfolio_analytic_1_analysis'

///// pinggy for secure connection
// https://rnsdx-68-173-160-106.a.free.pinggy.link/
// export const address = ''