/* models/widgets/textarea */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var logging_1 = require(14) /* core/logging */;
    var p = require(15) /* core/properties */;
    var dom_1 = require(5) /* core/dom */;
    var text_input_1 = require(428) /* ./text_input */;
    var TextAreaInputView = /** @class */ (function (_super) {
        tslib_1.__extends(TextAreaInputView, _super);
        function TextAreaInputView() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        TextAreaInputView.prototype.render = function () {
            var _this = this;
            _super.prototype.render.call(this);
            dom_1.empty(this.el);
            var labelEl = dom_1.label({ for: this.model.id }, this.model.title);
            this.el.appendChild(labelEl);
            this.inputEl = dom_1.textarea({
                class: "bk-widget-form-textarea",
                id: this.model.id,
                name: this.model.name,
                disabled: this.model.disabled,
                placeholder: this.model.placeholder,
                cols: this.model.cols,
                rows: this.model.rows,
                maxLength: this.model.max_length,
            });
            this.inputEl.append(this.model.value);
            this.inputEl.addEventListener("change", function () { return _this.change_input(); });
            this.el.appendChild(this.inputEl);
            // TODO - This 35 is a hack we should be able to compute it
            if (this.model.height)
                this.inputEl.style.height = this.model.height - 35 + "px";
        };
        TextAreaInputView.prototype.change_input = function () {
            var value = this.inputEl.value;
            logging_1.logger.debug("widget/text_input: value = " + value);
            this.model.value = value;
            _super.prototype.change_input.call(this);
        };
        return TextAreaInputView;
    }(text_input_1.BaseTextInputView));
    exports.TextAreaInputView = TextAreaInputView;
    var TextAreaInput = /** @class */ (function (_super) {
        tslib_1.__extends(TextAreaInput, _super);
        function TextAreaInput(attrs) {
            return _super.call(this, attrs) || this;
        }
        TextAreaInput.initClass = function () {
            this.prototype.type = "TextAreaInput";
            this.prototype.default_view = TextAreaInputView;
            this.define({
                cols: [p.Number, 20],
                rows: [p.Number, 2],
                max_length: [p.Number, 500],
            });
        };
        return TextAreaInput;
    }(text_input_1.TextInput));
    exports.TextAreaInput = TextAreaInput;
    TextAreaInput.initClass();
}
