/* models/widgets/panel */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var widget_1 = require(441) /* ./widget */;
    var p = require(15) /* core/properties */;
    var dom_1 = require(5) /* core/dom */;
    var PanelView = /** @class */ (function (_super) {
        tslib_1.__extends(PanelView, _super);
        function PanelView() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        PanelView.prototype.render = function () {
            _super.prototype.render.call(this);
            dom_1.empty(this.el);
        };
        return PanelView;
    }(widget_1.WidgetView));
    exports.PanelView = PanelView;
    var Panel = /** @class */ (function (_super) {
        tslib_1.__extends(Panel, _super);
        function Panel(attrs) {
            return _super.call(this, attrs) || this;
        }
        Panel.initClass = function () {
            this.prototype.type = "Panel";
            this.prototype.default_view = PanelView;
            this.define({
                title: [p.String, ""],
                child: [p.Instance],
                closable: [p.Bool, false],
            });
        };
        return Panel;
    }(widget_1.Widget));
    exports.Panel = Panel;
    Panel.initClass();
}
