/* models/transforms/transform */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var model_1 = require(59) /* ../../model */;
    var Transform = /** @class */ (function (_super) {
        tslib_1.__extends(Transform, _super);
        function Transform(attrs) {
            return _super.call(this, attrs) || this;
        }
        Transform.initClass = function () {
            this.prototype.type = "Transform";
        };
        return Transform;
    }(model_1.Model));
    exports.Transform = Transform;
    Transform.initClass();
}
