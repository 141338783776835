/* core/enums */ function _(require, module, exports) {
    exports.AngleUnits = ["deg", "rad"];
    exports.Dimension = ["width", "height"];
    exports.Dimensions = ["width", "height", "both"];
    exports.Direction = ["clock", "anticlock"];
    exports.FontStyle = ["normal", "italic", "bold", "bold italic"];
    exports.LatLon = ["lat", "lon"];
    exports.LineCap = ["butt", "round", "square"];
    exports.LineJoin = ["miter", "round", "bevel"];
    exports.Location = ["above", "below", "left", "right"];
    exports.LegendClickPolicy = ["none", "hide", "mute"];
    exports.LegendLocation = [
        "top_left", "top_center", "top_right",
        "center_left", "center", "center_right",
        "bottom_left", "bottom_center", "bottom_right",
    ];
    exports.Anchor = exports.LegendLocation;
    exports.Orientation = ["vertical", "horizontal"];
    exports.OutputBackend = ["canvas", "svg", "webgl"];
    exports.RenderLevel = ["image", "underlay", "glyph", "annotation", "overlay"];
    exports.RenderMode = ["canvas", "css"];
    exports.Side = ["above", "below", "left", "right"];
    exports.Place = ["above", "below", "left", "right", "center"];
    exports.SpatialUnits = ["screen", "data"];
    exports.StartEnd = ["start", "end"];
    exports.VerticalAlign = ["top", "middle", "bottom"];
    exports.TextAlign = ["left", "right", "center"];
    exports.TextBaseline = ["top", "middle", "bottom", "alphabetic", "hanging", "ideographic"];
    exports.TickLabelOrientation = ["vertical", "horizontal", "parallel", "normal"];
    exports.TooltipAttachment = ["horizontal", "vertical", "left", "right", "above", "below"];
    exports.Distribution = ["uniform", "normal"];
    exports.StepMode = ["after", "before", "center"];
    exports.SizingMode = ["stretch_both", "scale_width", "scale_height", "scale_both", "fixed"];
    exports.PaddingUnits = ["percent", "absolute"];
    exports.SliderCallbackPolicy = ["continuous", "throttle", "mouseup"];
    exports.RoundingFunction = ["round", "nearest", "floor", "rounddown", "ceil", "roundup"];
    exports.UpdateMode = ["replace", "append"];
    exports.HTTPMethod = ["POST", "GET"];
    exports.Logo = ["normal", "grey"];
}
