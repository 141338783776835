/* models/widgets/tables/table_widget */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var widget_1 = require(441) /* ../widget */;
    var cds_view_1 = require(199) /* ../../sources/cds_view */;
    var p = require(15) /* core/properties */;
    var TableWidget = /** @class */ (function (_super) {
        tslib_1.__extends(TableWidget, _super);
        function TableWidget(attrs) {
            return _super.call(this, attrs) || this;
        }
        TableWidget.initClass = function () {
            this.prototype.type = "TableWidget";
            this.define({
                source: [p.Instance],
                view: [p.Instance, function () { return new cds_view_1.CDSView(); }],
            });
        };
        TableWidget.prototype.initialize = function () {
            _super.prototype.initialize.call(this);
            if (this.view.source == null) {
                this.view.source = this.source;
                this.view.compute_indices();
            }
        };
        return TableWidget;
    }(widget_1.Widget));
    exports.TableWidget = TableWidget;
    TableWidget.initClass();
}
