/* models/widgets/button */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var p = require(15) /* core/properties */;
    var bokeh_events_1 = require(3) /* core/bokeh_events */;
    var abstract_button_1 = require(401) /* ./abstract_button */;
    var ButtonView = /** @class */ (function (_super) {
        tslib_1.__extends(ButtonView, _super);
        function ButtonView() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        ButtonView.prototype.change_input = function () {
            this.model.trigger_event(new bokeh_events_1.ButtonClick({}));
            this.model.clicks = this.model.clicks + 1;
            _super.prototype.change_input.call(this);
        };
        return ButtonView;
    }(abstract_button_1.AbstractButtonView));
    exports.ButtonView = ButtonView;
    var Button = /** @class */ (function (_super) {
        tslib_1.__extends(Button, _super);
        function Button(attrs) {
            return _super.call(this, attrs) || this;
        }
        Button.initClass = function () {
            this.prototype.type = "Button";
            this.prototype.default_view = ButtonView;
            this.define({
                clicks: [p.Number, 0],
            });
            bokeh_events_1.register_with_event(bokeh_events_1.ButtonClick, this);
        };
        return Button;
    }(abstract_button_1.AbstractButton));
    exports.Button = Button;
    Button.initClass();
}
