/* main */ function _(require, module, exports) {
    require(280) /* ./polyfill */;
    var version_1 = require(286) /* ./version */;
    exports.version = version_1.version;
    var embed = require(54) /* ./embed */;
    exports.embed = embed;
    var protocol = require(281) /* ./protocol */;
    exports.protocol = protocol;
    var _testing = require(285) /* ./testing */;
    exports._testing = _testing;
    var logging_1 = require(14) /* ./core/logging */;
    exports.logger = logging_1.logger;
    exports.set_log_level = logging_1.set_log_level;
    var settings_1 = require(18) /* ./core/settings */;
    exports.settings = settings_1.settings;
    var base_1 = require(0) /* ./base */;
    exports.Models = base_1.Models;
    exports.index = base_1.index;
    var document_1 = require(52) /* ./document */;
    exports.documents = document_1.documents;
    var safely_1 = require(284) /* ./safely */;
    exports.safely = safely_1.safely;
}
