/* models/callbacks/callback */ function _(require, module, exports) {
    var tslib_1 = require(391) /* tslib */;
    var model_1 = require(59) /* ../../model */;
    var Callback = /** @class */ (function (_super) {
        tslib_1.__extends(Callback, _super);
        function Callback(attrs) {
            return _super.call(this, attrs) || this;
        }
        Callback.initClass = function () {
            this.prototype.type = 'Callback';
        };
        return Callback;
    }(model_1.Model));
    exports.Callback = Callback;
    Callback.initClass();
}
