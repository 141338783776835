/* core/vectorization */ function _(require, module, exports) {
    var types_1 = require(44) /* core/util/types */;
    function isValue(obj) {
        return types_1.isObject(obj) && "value" in obj;
    }
    exports.isValue = isValue;
    function isField(obj) {
        return types_1.isObject(obj) && "field" in obj;
    }
    exports.isField = isField;
}
